:root {
  --bg-card: #1E2330;
  --bg-foter: #07090F;
  --bg-main: #FFFFFF;
  --bg-modal: linear-gradient(180deg, #1E2330 0%, #000614 100%);
  --bg-navbar: #0D0F14;
  --bg-popup: linear-gradient(180deg, #EC0000 0%, #9C0000 100%);
  --bg-text-animation: #1E2330;
  --bt-confirm: linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%);
  --bt-danger: #FF0000;
  --bt-defult: #FFFFFF;
  --bt-info: #434958;
  --bt-login: #FFDE8B;
  --bt-register: #2B00D6;
  --bt-secondary: #C9C9C9;
  --bt-sucess: #00ED09;
  --bt-undefult: #000000;
  --bt-warning: #FFD600;
}

/* html,
body {
  overflow: hidden;
} */

body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  color: var(--bg-main);
  /* background: #F0F1F9; */
  /* background: white; */
}

::placeholder {
  color: var(--bt-undefult);
  opacity: 0.8;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.kanit,
button {
  font-family: 'Sarabun', sans-serif;
}

/* .michroma {
  font-family: "Michroma", sans-serif;
} */

/* .roboto {
  font-family: "Roboto", sans-serif;
} */

/* .chakra {
  font-family: "Chakra Petch", sans-serif;
} */

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.MuiTypography-body1 {
  font-family: 'Sarabun', sans-serif !important;
}

/* .GameApp ::-webkit-scrollbar {
  height: 18px;
  max-width: 4px;
  background: transparent;
}

.GameApp ::-webkit-scrollbar-thumb:horizontal {
  background: rgb(255, 193, 7);
  border-radius: 10px;
  cursor: pointer !important;
  filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 80%));
  border: solid 2px darkgrey;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.GameApp ::-webkit-scrollbar-track {
  background-color: darkgrey;
} */

input.readOnly:read-only {
  background: #dddddd;
  color: black;
  cursor: not-allowed;
}

.textFalse {
  opacity: 0.6;
}

.bgImage {
  background: url("./images/bg/bg.webp") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: initial;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}

/* .textColorComment {
  color: rgb(255, 193, 7);
} */
