:root {
  --bg-card: #1E2330;
  --bg-foter: #07090F;
  --bg-main: #FFFFFF;
  --bg-modal: linear-gradient(180deg, #1E2330 0%, #000614 100%);
  --bg-navbar: #0D0F14;
  --bg-popup: linear-gradient(180deg, #EC0000 0%, #9C0000 100%);
  --bg-text-animation: #1E2330;
  --bt-confirm: linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%);
  --bt-danger: #FF0000;
  --bt-defult: #FFFFFF;
  --bt-info: #434958;
  --bt-login: #FFDE8B;
  --bt-register: #2B00D6;
  --bt-secondary: #C9C9C9;
  --bt-sucess: #00ED09;
  --bt-undefult: #000000;
  --bt-warning: #FFD600;
}

.flexCenter {
  display: flex;
}

.boxFilter {
  background: var(--bg-card);
  padding: 14px;
  border-radius: 14px;
  /* color: var(--bg-main); */
}

.cardOtherListHeadText {
  font-size: 20px;
  font-weight: 600;
  /* color: var(--bg-main); */
}

.cardOtherListHeadIconSize {
  width: 38px;
  height: auto;
}

/* .cardOtherListIconAcion {
  padding: 10px;
  border-radius: 100%;
  background: transparent;
  box-shadow: 0px 2px 4px var(--bg-main);
  cursor: pointer;
  position: relative;
  margin-top: 12px;
  color: red;
}

.cardOtherListIconAcion:hover {
  top: 2px;
  background: var(--bg-card);
  color: white;
}

.cardOtherListIconAcionSize {
  width: 44px !important;
  height: 44px !important;
} */

.cardOtherListHead,
.cardOtherListHeadRight {
  background: var(--bg-card);
  display: inline-block;
  padding: 10px 40px 0px 40px;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  z-index: 500;
  color: var(--bg-main);
  font-size: 18px;
  height: 36px;
  min-width: 80px;
}

.cardOtherListHeadWhite {
  background: var(--bg-card);
  display: inline-block;
  padding: 10px 40px 0px 40px;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  z-index: 500;
  color: var(--bg-main);
  font-size: 18px;
  height: 36px;
  min-width: 80px;
}

.cardOtherListBodyWhite {
  background: var(--bg-card) !important;
  color: var(--bg-main);
  padding: 18px 18px 12px 18px !important;
  border-bottom: var(--bg-main) dotted 2px;
  margin-top: -2px;
  border-radius: 15px;
}

.cardOtherListHeadWhite::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -4px;
  width: 70%;
  height: 100%;
  border-radius: 0 8px 0 0;
  transform: skewX(20deg);
  background: var(--bg-card);
  z-index: -1;
}

.cardOtherListHead::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -4px;
  width: 70%;
  height: 100%;
  border-radius: 0 8px 0 0;
  transform: skewX(20deg);
  background: var(--bg-card);
  z-index: -1;
}

.cardOtherListHeadRight::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -4px;
  width: 70%;
  height: 100%;
  border-radius: 8px 0 0 0;
  transform: skewX(-20deg);
  background: var(--bg-card);
  z-index: -1;
}

.cardOtherListBody {
  background: var(--bg-card);
  border-radius: 15px;
  padding: 18px;
}

.contactService {
  color: var(--bt-danger);
  border-bottom: 1px solid var(--bt-danger);
  cursor: pointer;
}

.contactService:hover {
  opacity: 0.8;
}

.iconCardSize {
  margin-top: 6px;
  width: 28px;
  height: 28px;
}

.iconClose {
  width: 60px;
  height: 100%;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
  opacity: 1;
}

.iconClose:hover {
  top: 2px;
}

.gameSize {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}

.menuPosition {
  padding: 12px 0px;
}

.menuPositionWidthScroll {
  width: auto;
  /* white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden; */
  margin-left: 8px;
  margin-right: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
}

.menuPositionWidthScroll::-webkit-scrollbar {
  height: 14px !important;
}

.menuPositionWidthScroll::-webkit-scrollbar-thumb {
  background: var(--bt-defult);
  border-radius: 20px;
}

.iconSize {
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;
}

/* .titleShapeBorder {
  background: white;
  clip-path: polygon(
    15% 0,
    100% 0,
    100% 65%,
    85% 100%,
    20% 100%,
    0 100%,
    0 40%
  );
  padding: 2px;
}

.titleShapeBorderBG {
  background: #07185e;
  clip-path: polygon(
    15% 0,
    100% 0,
    100% 65%,
    85% 100%,
    20% 100%,
    0 100%,
    0 40%
  );
} */

.headTitlePosition {
  position: absolute;
  left: 40px;
  top: -26px;
  z-index: 1200;
}

.headTitlePositionCenter {
  position: absolute;
  left: 50%;
  top: -26px;
  z-index: 1200;
  transform: translateX(-50%);
}

.headTitlePositionCenterLeft {
  position: absolute;
  left: 20%;
  top: -26px;
  z-index: 1200;
  transform: translateX(-20%);
}

.boxObjectLeftPosition {
  position: absolute;
  left: -20px;
  top: 28%;
}

.boxObjectRightPosition {
  position: absolute;
  right: -20px;
  top: 16%;
}

.boxObjectSize {
  width: 22px;
  height: auto;
}

/* .headTitle {
  height: 40px;
  line-height: 40px;
  font-size: 26px;
  color: white;
  padding: 12px 40px;
} */

.titleRule {
  font-size: 18px;
  color: var(--bt-warning);
  /* border-bottom: solid 1px var(--bt-warning); */
  cursor: pointer;
}

.titleRule:hover {
  opacity: 0.8;
}

.boxContentTitle {
  font-size: 20px;
  color: var(--bt-defult);
}

.boxNoneBankMargin {
  margin-top: 20px;
}

.boxNoneBank {
  background: var(--bg-card);
  padding: 16px 20px;
  color: var(--bt-defult);
  text-align: center;
  border-radius: 16px;
}

.boxNoneBankTitle {
  font-size: 16px;
}

.boxNoneBankGoto {
  color: var(--bt-warning);
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
  border-bottom: 1px solid var(--bt-warning);
  cursor: pointer;
}

.boxNoneBankGoto:hover {
  opacity: 0.8;
}

.buttonContentWrapper {
  position: relative;
  cursor: pointer;
  text-align: center;
  /* height: calc(12vw - 30px); */
}

.buttonContentWrapper:hover {
  top: 2px;
}

.buttonContentPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.buttonRule {
  position: relative;
  /* background: #001a84;
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 6px #05a5ff; */
  /* border-radius: 10px; */
  font-size: 16px;
  color: var(--bt-warning);
  font-style: normal;
  font-weight: normal;
  /* padding: 6px 18px; */
  cursor: pointer;
}

.buttonRule:hover {
  top: 2px;
}

/* .buttonSize {
  width: 100%;
  filter: drop-shadow(0px 0px 15px rgba(0, 133, 255, 0.8));
}

.buttonSubSize {
  width: 72%;
  filter: drop-shadow(0px 0px 15px rgba(0, 133, 255, 0.8));
} */

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.white {
  color: white !important;
}

.yellow {
  color: #ffc700 !important;
}

.iconSizeTitle {
  width: 52px;
  height: 52px;
}

.iconSizeSubTitle {
  width: 52px;
  height: 52px;
}

.iconSizeSub {
  width: 42px;
  height: 42px;
}

/* .buttonContentText {
  font-size: 18px;
  text-shadow: 4px 4px 22px #000;
  margin-top: -3px;
}

.buttonContentTextSub {
  font-size: 18px;
  text-shadow: 4px 4px 22px #000;
} */

/* .selectDeposit {
  background-color: white;
  color: #3a068f !important;
  padding: 8px 12px;
  border: solid 1px white;
  font-size: 16px !important;
  border-radius: 12px;
} */

.boxBankCustomer {
  padding: 16px;
  /* background: var(--bg-card); */
  background: var(--bt-defult);
  border-radius: 15px;
  color: var(--bt-undefult);
}

.textBankMoney {
  color: var(--bt-secondary);
}

.boxBankAgentBorder {
  border: 1px solid var(--bt-defult);
  border-radius: 15px;
  /* text-align: center; */
  padding: 20px 13px;
  line-height: 25px;
}

.iconBankAgentSize {
  width: 86px !important;
  height: 86px !important;
  color: var(--bt-defult);
}

/* .MuiSelect-icon svg {
  color: #3a068f !important;
} */

.bankTitile {
  /* font-family: "Kanit", sans-serif !important; */
}

.boxContentPromotionTitle {
  font-size: 34px;
  margin-top: -10px;
  color: var(--bt-defult);
  /* text-shadow: 0px 0px 4px rgba(113, 246, 255, 0.73); */
}

.inputMoneyMargin {
  /* margin-top: -18px; */
  padding: 8px 0px;
  font-size: 16px !important;
}

.inputBorder {
  border-bottom: 2px dashed var(--bt-defult);
  padding: 6px 0px;
  padding-bottom: 30px;
}

.inputBorderPadding {
  padding: 8px 6px !important;
  border-radius: 40px;
}

.inputLabelPadding {
  padding: 6px 6px 5px 6px;
}

.inputLabelBG {
  /* background: #081c6a; */
  color: var(--bt-defult);
  padding: 6px;
  box-sizing: border-box;
  /* box-shadow: inset 0px 0px 5px 1px rgb(33 82 255 / 97%); */
}

.inputAdornment {
  font-size: 16px;
}

/* .buttonNextPosition {
  position: absolute;
  bottom: 0px;
  left: 50%;
  z-index: 1200;
  transform: translateX(-50%);
}

.buttonCancelPosition {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1200;
  transform: translateX(-50%);
} */

/* .promotionScroll {
  height: 68%;
  overflow: scroll;
}

.selectPromotion {
  background: #ffffff;
  padding: 8px 20px;
  border: 2px solid white;
  border-radius: 8px;
  color: #140041 !important;
}

.selectPromotionText {
  font-size: 18px;
  cursor: pointer;
}

.selectPromotionTextTtile {
  font-size: 20px;
}

.selectPromotionTextSub {
  font-size: 16px;
}

.selectPromotionTextDetial {
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
  border-bottom: 1px solid #ff0000;
}

.selectPromotionTextDetial:hover {
  color: #b10a0a;
  border-bottom: 1px solid #b10a0a;
}

.selectPromotionRadioTop {
  margin-top: 12px;
  text-align: right;
} */

.radioInputSize {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* .titleTimeBackground {
  background: #060c39d1;
  text-align: center;
  color: white;
}

.timeIconSize {
  width: 60px;
  height: 60px;
}

.timeTextCenter {
  font-size: 18px;
  margin-top: 10px;
}

.timeTextSize {
  text-align: right;
  font-size: 26px;
  padding: 0px 6px 0px 0px;
} */

.displayInherit {
  display: inherit;
}

.boxDetailDeposit {
  margin-top: 30px;
}

.boxDetailTop {
  padding: 22px 16px;
  background: var(--bt-defult);
  border-radius: 15px;
  font-size: 16px;
  color: black;
}

.boxDetailAmount {
  /* background: #efe6ff; */
  border-radius: 16px;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}

.boxDetailAmountPadding {
  padding: 18px;
}

.boxDetailAmountNumber {
  background: var(--bt-danger);
  border-radius: 10px;
  padding: 4px 2px;
  color: var(--bt-defult);
  font-size: 36px;
  font-weight: 800;
  text-align: center;
}

.boxDetailAgent {
  font-size: 16px;
  font-weight: 400;
}

/* .boxProcessing {
  font-size: 16px;
  background: linear-gradient(169.65deg, #fff960 -34.09%, #ff8c22 100.99%);
} */

/* .boxDetailbottom {
  padding: 18px 40px;
  background: #060c39d1;
} */

/* .boxAccountAgent {
  margin-top: 20px;
  background: #060c39d1;
  padding: 8px 10px;
} */

.boxAccountAgentTitle {
  font-size: 16px;
  font-weight: 700;
}

.boxAccountAgentSub {
  font-size: 15px;
  font-weight: 700;
}


/* .boxAccountAgentCopy {
  background: linear-gradient(180deg, #280368 0%, #1c004d 100%);
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50px;
  padding: 4px 24px;
}

.boxAccountAgentCopy:hover {
  background: #3f1589;
} */

.bankAccountLogo {
  width: 32px;
}

.bankAccountLogo img {
  border-radius: 50px;
}

/* .bankAccountName {
  font-size: 16px;
  color: white;
  text-align: center;
} */

/* .bankPromotionLabelDanger {
  font-size: 16px;
  background: linear-gradient(180deg, #ff0000 0%, #bb0000 100%);
  color: white;
  padding: 2px 16px;
  border-radius: 50px;
} */

/* .bankPromotionLabelSuccess {
  font-size: 16px;
  background: linear-gradient(180deg, #06c755 0%, #00712e 100%);
  color: white;
  padding: 2px 16px;
  border-radius: 50px;
} */

/* .bankPromotionLabelCopyNumber {
  position: relative;
  font-size: 14px;
  background: rgb(255, 193, 7);
  color: #060c39;
  padding: 2px 16px;
  border-radius: 50px;
  cursor: pointer;
}

.bankPromotionLabelCopyNumber:hover {
  top: 2px;
  background: rgb(216, 167, 19);
} */

/* .bankPromotionText {
  color: white;
  margin-top: 12px;
  font-size: 18px;
} */

/* .bankWithdrawText {
  color: white;
  margin-top: 12px;
  font-size: 16px;
  text-align: right;
} */

/* .bankPromotionMoney {
  font-size: 30px;
  text-align: right;
  color: white;
} */

/* .bankPromotionUnit {
  font-size: 16px;
  padding-left: 8px;
} */

/* .bankPromotionTextBonus {
  color: #ffffffbf;
  font-size: 16px;
} */

/* .bankPromotionMoneyBonus {
  color: #ffffffbf;
  font-size: 20px;
  text-align: right;
} */

.imgPromotionNone {
  width: 220px;
}

.imgPromotionList {
  /* position: relative; */
  cursor: pointer;
  width: 240px;
  height: auto;
  border-radius: 10px;
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.imgPromotionListBorder {
  cursor: pointer;
  width: 240px;
  height: auto;
  border-radius: 10px;
  margin-right: 10px;
  /* margin-bottom: 10px; */
  border: 3px solid var(--bt-danger);
}

.imgPromotionList:hover {
  /* top: 2px; */
  border: 3px solid #0066FF;
}

.bankPromotionComment {
  color: var(--bt-danger);
  font-size: 16px;
  font-weight: 600;
}

.iconTransactionPadding {
  padding: 0px 40px;
  margin: 10px 0px 5px 0px;
}

.iconTransactionBottomSize {
  width: 18px;
  height: auto;
}

/* .buttonGetPromotionBG {
  background: rgba(7, 24, 94, 0.5);
  cursor: pointer;
  position: relative;
}

.buttonGetPromotionBG:hover {
  top: 2px;
} */

/* .promotionImgSize {
  width: 100%;
  height: 100%;
} */

/* .buttonGetPromotion {
  padding: 8px;
  font-size: 18px;
  background: linear-gradient(
    93.84deg,
    rgba(54, 72, 225, 0.7) 7.32%,
    rgba(154, 119, 255, 0.7) 93.66%
  );
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  box-shadow: inset 0px 0px 6px rgba(255, 255, 255, 0.35);
  color: white;
} */

/* .boxDepositMnualTop {
  padding: 16px 24px 24px 24px;
  background: #060c39d1;
  border-radius: 8px;
} */

/* .bankAccountDataTitle {
  font-size: 18px;
  color: white;
} */

/* .bankAccountData {
  text-align: left;
  color: #c2c2c2;
} */

/* .inputLabelDateBG {
  background: #041b60;
}

.inputLabelDatePadding {
  color: white;
  padding-top: 4px;
}

.inputDateBG {
  background: white;
} */

/* .boxDepositMnualNotShape {
  padding: 16px 24px 24px 24px;
  background: #060c39d1;
} */

.boxMarginTop {
  margin-top: 12px !important;
}

.boxMarginTopScroll {
  margin-top: 12px !important;
}

.boxSubMarginTop {
  margin-top: 22px !important;
}

.boxButtonMarginTop {
  margin-top: 40px !important;
}

/* .boxButtonSubMarginTop {
  margin-top: 18px !important;
} */
.boxComemntTop {
  font-size: 18px;
  margin-top: 28px !important;
}

.boxComemntDeposit {
  font-size: 16px;
  margin-top: 28px !important;
}

.buttonDepositSize {
  width: 100%;
  height: 100%;
}

.buttonDepositSubSize {
  width: 310px;
  height: 100%;
}

/* .buttonContentPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .black_button {
  background: url("https://a-player.711testapi.com/images/button/black_button.svg");
  background-size: 100% 100%;
  padding: 12px;
}

.blue_button {
  background: url("https://a-player.711testapi.com/images/button/blue_button.webp");
  background-size: 100% 100%;
  padding: 12px;
  height: 100%;
}

.blue_button_second {
  background: url("https://a-player.711testapi.com/images/button/blue_button_second.webp");
  background-size: 100% 100%;
  padding: 12px;
} */

.formPadding {
  padding: "0px 20px 0px 20px";
}
.boxContentLineTitle {
  font-size: 20px;
  color: var(--bt-defult);
  /* text-shadow: 0px 0px 4px rgba(113, 246, 255, 0.73); */
}
.processLineText {
  text-align: center;
  color: var(--bt-defult);
  padding-top: 12px;
}

.boxMarginFormTop {
  margin-top: 30px !important;
}

.boxMarginMediumTop {
  margin-top: 20px !important;
}

.promotionBox {
  position: relative;
}

.promotionBoxImg {
  width: 100%;
  height: 200px;
  filter: drop-shadow(0px 4px 8px rgba(0, 133, 255, 0.8));
}

.promotionBoxContentPosition {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  text-align: left;
}

.promotionBoxContentTitle {
  font-size: 18px;
  font-weight: bold;
}

.promotionBoxContentText {
  font-size: 16px;
  margin-bottom: 2px;
  /* margin-top: -6px; */
}

.promotionBoxContentIconSize {
  width: 24px !important;
  height: 24px !important;
}

.promotionBoxContentTextSub {
  font-size: 16px;
  color: var(--bt-undefult);
  /* background: var(--bg-navbar); */
  /* padding: 0px 24px; */
  border-radius: 20px;
  margin-left: 12px;
}

.textComment {
  font-size: 14px;
  margin-top: 4px;
}

.textWarning {
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: -10px;
  color: rgb(255, 193, 7);
}

.promotionTextRule {
  font-size: 14px;
  cursor: pointer;
}

.promotionTextRule:hover {
  color: #cecece;
}

.promotionBoxContentMargin {
  margin-bottom: 4px;
}

.promotionBoxButtonPosition {
  position: absolute;
  bottom: 6px;
  right: 22px;
  text-align: left;
}

.balanceAmount {
  color: #07185e;
  display: inline-block;
  background: rgb(255, 193, 7);
  margin-top: 8px;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 16px;
}

.balanceResult {
  color: white;
  display: inline-block;
  background: #dc3545;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  margin-top: 12px;
}

/* .contactEm {
  margin-top: 12px;
  font-size: 18px;
  color: rgb(255, 193, 7);
  display: inline-block;
  border-bottom: 1px solid rgb(255, 193, 7);
  cursor: pointer;
}

.contactEm:hover {
  color: rgb(161, 124, 11);
  border-bottom: 1px solid rgb(161, 124, 11);
} */

/* .historyLink {
  display: inline-block;
  color: #330582;
  border-bottom: 1px solid #330582;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
}

.historyLink:hover {
  color: #5618c0;
  border-bottom: 1px solid #5618c0;
} */

.confirmText {
  font-size: 20px;
  color: white;
}

.confirmParagrap {
  font-size: 18px;
  color: white;
}

.depositDate {
  border: 1px solid var(--bt-undefult);
  border-radius: 10px;
  padding: 6px 14px;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 34px;
  /* border: 1px solid #000000; */
  /* border-radius: 10px; */
}
.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--bt-defult);
  padding: 10px 0px;
  /* padding-left: 100px; */
  display: block;
  /* width: 70%; */
  pointer-events: none;
  z-index: 20;
  height: 14px;
  line-height: 14px;
  color: var(--bt-undefult);
  border-radius: 10px;
  font-weight: 300;
}
.file-upload-wrapper:before {
  /* content: "อัพโหลด";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 40px;
  background: linear-gradient(180deg, #4128FF 0%, #7F28FF 100%);
  color: #fff;
  font-weight: 700;
  z-index: 25;
  font-size: 16px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 10px 0 0 10px; */
}
.file-upload-wrapper:hover:before {
  /* background: #7F28FF; */
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 34px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.cardGameMenu {
  padding: 0px 12px 12px 12px;
}

.rankUserTitle {
  font-size: 20px;
  font-weight: 600;
  margin-top: -15px;
  padding-bottom: 10px;
  text-align: center;
  /* color: var(--bg-main); */
}

.rankUserStatusTitle {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.rankLeftBorder {
  margin-top: -20px;
  margin-left: 0.5px;
}

.rankRightBorder {
  float: right;
  margin-top: -20px;
  margin-right: 0.5px;
}

.rankImgFooter {
  margin-top: -7px;
}

.rankImgMiddle {
  width: 100%;
}

.rankUserStatus {
  font-size: 20px;
  font-weight: 600;
  /* margin-top: -20px; */
  /* padding-bottom: 10px; */
  text-align: center;
  /* color: var(--bg-main); */
}

.rankImgHeader, .rankImgFooter, .rankImgMiddle {
  width: 100%;
}

.selectBankStyles {
  background: var(--bt-defult);
  border-radius: 10px;
  padding: 10px 6px;
  font-size: 18px;
  line-height: 21px;
  color: var(--bt-undefult);
  height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.selectBankStyles option {
  color: var(--bt-undefult) !important;
}

@media only screen and (max-width: 960px) {
  .headTitlePosition {
    top: -16px;
  }
  .headTitlePositionCenter {
    top: -16px;
  }

  .headTitlePositionCenterLeft {
    top: -14px;
    left: 24%;
    transform: translateX(-22%);
  }
  .headTitle {
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    padding: 4px 30px;
  }
  .boxObjectSize {
    width: 14px;
  }
  .boxObjectLeftPosition {
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .boxObjectRightPosition {
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .closeButton {
    top: -6px;
    right: -6px;
  }
  .backButton {
    top: -6px;
    right: -6px;
  }
  /* .iconSizeTitle {
    width: 7vw;
    height: 10vh;
  } */
  /* .buttonSize {
    width: 192px;
  } */
  .iconSize {
    width: 30px !important;
    height: 30px !important;
  }
  /* .iconSizeSub {
    width: 6vw;
    height: 8vh;
  } */
  /* .iconSizeSubTitle {
    width: 2.6em;
    height: 2.4em;
  } */
  /* .boxButtonSubMarginTop {
    margin-top: 10px !important;
  } */
  /* .buttonSubSize {
    width: 100%;
  } */
  .yellow_button,
  .red_button {
    padding: 2px;
    height: calc(30vh - 30px);
  }

  /* .buttonDepositSize {
    width: 92%;
    height: 100%;
  } */

  /* .black_button,
  .blue_button {
    padding: 2px;
    height: calc(25vh - 30px);
  }
  .blue_button_second {
    padding: 6px;
  } */
  .boxSubMarginTop {
    margin-top: 12px !important;
  }
  .numberButtonShapeBorder {
    margin-bottom: 2px;
  }
  /* .selectDeposit {
    font-size: 14px !important;
  } */
  .inputAdornment {
    font-size: 12px;
  }
  .inputMoneyMargin {
    font-size: 12px !important;
    padding: 4px 0px;
  }
  .inputLabelPadding {
    padding: 4px 6px 3px 6px;
  }
  .boxContentPromotionTitle {
    text-align: center;
    font-size: 26px;
  }
  .buttonGetPromotion {
    font-size: 12px;
  }
  .boxContentTitleMobile {
    font-size: 14px;
  }
  .radioInputSize {
    width: 14px;
    height: 14px;
  }
  .buttonRule {
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 8px;
  }
  .buttonNextPosition {
    /* left: 200px; */
    /* transform: translateX(-24%); */
  }
  .timeTextSize {
    font-size: 22px;
  }
  /* .bankPromotionMoney {
    font-size: 16px;
    margin-top: 6px;
  }
  .bankPromotionMoneyBonus {
    font-size: 16px;
  } */
  /* .bankPromotionUnit {
    font-size: 12px;
  } */
  .boxDetailbottom {
    padding: 18px 24px;
  }
  .boxDepositMnualTop {
    padding: 6px 12px 12px 12px;
  }
  .inputLabelDatePadding {
    font-size: 12px;
  }
  .confirmParagrap {
    font-size: 16px;
  }
  .confirmText {
    font-size: 18px;
  }
  .hideScrollbar::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

@media only screen and (max-width: 600px) {
  .inputLabelBG {
    font-size: 14px;
    padding: 6px 0px;
  }
  .buttonNextPosition {
    /* left: 170px; */
  }
  .selectPromotionTextTtile {
    font-size: 16px;
  }
  .selectPromotionTextDetial {
    font-size: 12px;
  }
  .selectPromotionTextSub {
    font-size: 12px;
  }
  .selectPromotionRadioTop {
    margin-top: 2px;
  }
  .boxComemntTop {
    font-size: 16px;
  }
  .iconSizeTitle {
    width: 42px;
    height: 42px;
  }

  .iconSizeSubTitle {
    width: 42px;
    height: 42px;
  }

  .iconSizeSub {
    width: 32px;
    height: 32px;
  }

  .buttonContentText {
    font-size: 14px;
  }
  .buttonContentTextSub {
    font-size: 12px;
    margin-top: -3px;
  }
  .buttonDepositSubSize {
    width: 210px;
    height: auto;
  }
  .boxButtonMarginTop {
    margin-top: 22px !important;
  }
  .boxContentTitle {
    font-size: 16px;
  }
  .timeTextSize {
    font-size: 16px;
  }
  .timeTextCenter {
    font-size: 14px;
    margin-top: 6px;
  }
  /* .boxAccountAgentText {
    font-size: 12px;
  } */
  .bankPromotionLabelCopyNumber {
    font-size: 10px;
  }
  .boxAccountAgentCopy {
    font-size: 10px;
  }
  /* .bankAccountName {
    font-size: 12px;
  } */
  .bankPromotionLabelDanger {
    font-size: 12px;
  }
  .bankPromotionText {
    font-size: 14px;
    margin-top: 6px;
  }
  .bankPromotionTextBonus {
    font-size: 14px;
  }
  .bankPromotionComment {
    margin-top: 6px;
    font-size: 14px;
  }
  .bankAccountData {
    font-size: 12px;
  }
  .bankAccountDataTitle {
    font-size: 16px;
  }
  .timeTextNote {
    font-size: 12px;
  }
  .promotionBoxImg {
    height: 150px;
  }
  .textComment {
    font-size: 12px;
  }
  .promotionBoxContentTitle {
    font-size: 16px;
  }
  .promotionBoxContentIconSize {
    width: 16px !important;
    height: 16px !important;
  }
  .promotionBoxContentText {
    font-size: 13px;
  }
  .promotionBoxContentTextSub {
    font-size: 14px;
  }
  .promotionBoxContent {
    padding: 0px 24px;
  }
  .textWarning {
    font-size: 14px;
  }
  .cardOtherListHead,
  .cardOtherListHeadRight,
  .cardOtherListHeadWhite {
    height: 28px;
    padding: 8px 20px 0px 20px;
    font-size: 16px;
    min-width: 40px;
  }
  .iconClose {
    width: 50px;
  }
  .menuPosition {
    padding: 6px 0px;
  }
  .cardOtherListBody {
    padding: 12px 12px 12px 12px;
    font-size: 14px;
    border-radius: 0px;
  }
  .cardOtherListBodyWhite {
    padding: 12px 12px 12px 12px;
    font-size: 14px;
    border-radius: 15px;
  }
  .imgPromotionNone {
    width: 180px;
  }
  .boxDetailAgent {
    font-size: 14px !important;
  }
  .boxDetailAmount {
    font-size: 14px;
    padding: 8px;
  }
  /* .boxAccountAgentTitle {
    font-size: 16px;
  } */
  .boxAccountAgentSub {
    font-size: 14px;
  }
  .boxDetailAmountNumber {
    font-size: 26px;
  }
  /* .gameSize {
    height: 180px;
  } */
  .historyLink {
    font-size: 16px;
  }
  .cardOtherListHeadText {
    font-size: 17px;
  }
  .cardOtherListHeadIconSize {
    width: 24px;
  }
  .cardOtherListIconAcionSize {
    width: 24px !important;
    height: 24px !important;
  }
  .cardOtherListIconAcion {
    padding: 8px;
  }
  .boxFilter {
    border-radius: 0px;
    padding: 6px 14px;
  }
  .boxMarginMediumTop {
    margin-top: 10px !important;
  }
  .rankImgHeader, .rankImgFooter, .rankImgMiddle {
    width: 100%;
  }
  .rankRightBorder {
    margin-right: 0px;
  }
  .rankLeftBorder {
    margin-top: -23px;
    margin-left: 0px;
  }
  .rankRightBorder {
    float: right;
    margin-top: -23px;
    margin-right: 0px;
  }
  .rankUserTitle {
    font-size: 18px;
    font-weight: 600;
    margin-top: -20px;
    padding-bottom: 12px;
    text-align: center;
    /* color: var(--bg-main); */
  }
  .rankUserStatusTitle {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
  .menuPositionWidthScroll::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .menuPositionWidthScroll::-webkit-scrollbar {
    height: 0px !important;
  }
}
